body {
	margin: 0;
	padding: 0;
}

a {
	color: #319795;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		text-decoration-style: dotted;
	}
}

th,
td {
	white-space: nowrap;
}

.MuiTableContainer-root,
.MuiTablePagination-root {
	z-index: 5;
}

#connectors .MuiFormLabel-root {
	font-size: x-small !important;
}

.serviceWorkerSnackbar {
	font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
	font-size: 0.875rem;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	z-index: 50;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	margin: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	border-radius: 0.25rem;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
	color: #ffffff;
	background-color: #319795;
	.content {
		flex: 1 1 0%;
		margin-right: 0.5rem;
		.refreshButton {
			margin-left: 0.5rem;
			font-weight: 700;
			background-color: transparent;
			border: 0;
			color: #fff;
			cursor: pointer;
			&:focus {
				outline: none;
			}
		}
	}
	.closeButton {
		margin-left: 0.5rem;
		background-color: transparent;
		border: 0;
		color: #fff;
		cursor: pointer;
		font-size: 1.5rem;
		&:focus {
			outline: none;
		}
	}
}

.serviceWorkerSnackbar.hidden {
	display: none;
}

/* md */
@media (min-width: 768px) {
	.serviceWorkerSnackbar {
		left: auto;
	}
}
