.userCard {
	background-color: #555555;
	padding: 0.5rem;
	color: #ffffff;
	border-radius: 5px;
	-webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
	h6 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.iconButton {
	color: #ffffff !important;
}

.version {
	bottom: 2px;
	padding-left: 2px;
	position: fixed;
	font-size: 0.8rem !important;
	font-weight: bold !important;
}

#sidenav-mobile {
	#drawerHeader {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../img/background.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 1rem 1rem 0.5rem 1rem;
	}
	.nested {
		padding-left: 2rem;
	}
}

#home {
	min-height: 100vh;
	#bodyContainer {
		flex-wrap: nowrap;
		#body {
			flex: 1;
			margin-top: -10rem;
			margin-left: 1rem;
			margin-right: 1rem;
			margin-bottom: 1rem;
			max-width: calc(100vw - 15rem);
			#homePage {
				margin-top: 12rem;
				#arrow {
					width: 8rem;
				}
				#label {
					width: 10rem;
					margin-left: 3rem;
					text-align: center;
				}
			}
		}
	}

	#header {
		height: 20rem;
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../img/background.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		#headerTop {
			padding: 2rem 2rem 1rem;
			#thorImage {
				height: 3rem;
			}
		}
		#headerBottom {
			width: 15rem;
			margin-bottom: 1rem;
		}
		#headerMobile {
			display: none;
		}
	}

	#sidenav {
		width: 15rem;
		.nested {
			padding-left: 2rem;
		}
	}

	@media screen and (max-width: 800px) {
		#body {
			max-width: calc(100vw - 2rem) !important;
			margin: 1rem 1rem 0rem 1rem !important;
			#homePage {
				margin-top: 0px !important;
			}
		}

		#sidenav {
			display: none;
		}

		#header {
			height: unset;

			#headerMobile {
				display: flex;
				padding: 1rem;
				#thorImage {
					max-height: 2rem;
					max-width: 75%;
				}
			}

			#headerTop,
			#headerBottom {
				display: none;
			}
		}
	}
}
